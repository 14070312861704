@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900);
* {
  outline: none !important;
}

body {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #00AEE9;
  text-align: left;
  background-color: #ffffff
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}

html {
  background-color: #ffffff;
}

.App-banner {
  margin-top: 7vh;
  margin-bottom: 7vh;
  justify-content: center;
  display: flex;
  flex-direction: row;
  max-height: 10vh;
  color: #1B295E;
}

.App-header {
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1B295E;
}

.App-logo {
  width: 10vh;
  height: auto;
  pointer-events: none;
}

.App-title {
  margin-left: 1.5vh;
  font-size: 3vh;
  font-weight: 1;
  color: #00AEE9;
}

.App-link {
  color: #61dafb;
}

.Commands {
  /* margin-top: 10vh; */
  margin-bottom: 6vh;
  display: flex;
  flex-direction: row;
}

.Toastify__toast-container {
  width: 400px;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

@media only screen and (orientation: landscape) {
  .App-footer {
    margin-top: 4vh;
    font-size: 1.2vh;
  }
}

@media only screen and (orientation: portrait) {
  .App-footer {
    margin-top: 4vw;
    font-size: 1.2vw;
  }
}

.BackupButton {
  border: none;
  color: white;
  border-radius: 12px;
  opacity: 1;
  transition: 0.3s;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (orientation: landscape) {
  .BackupButton {
    width: 12vw;
    height: 8vw;
    font-size: 3vw;
  }
}

@media only screen and (orientation: portrait) {
  .BackupButton {
    width: 15vh;
    height: 10vh;
    font-size: 3vh;
  }
}

.BackupButton:disabled {opacity: 0.5}

.BackupButton:hover:enabled {opacity: 0.8}
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/

.accordion {
    text-align: left;
    font: Monaco,Consolas,"Andale  Mono","DejaVu Sans Mono",monospace;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

@media only screen and (orientation: landscape) {
  .accordion {
    width: 70vw;
    font-size: 1.2vw;
  }
}

@media only screen and (orientation: portrait) {
  .accordion {
    width: 90vw;
    font-size: 1.2vh;
  }
}


.accordion__item + .accordion__item {
    border-top: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__button {
    background-color: #758796;
    color: #f4f4f4;
    cursor: pointer;
    padding: 18px;
    text-align: left;
    border: none;
    transition: 0.3s;
}

.accordion__button:hover {
    background-color: #758796;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 1vh;
    width: 1vh;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

@media only screen and (orientation: landscape) {
  .accordion__button:before {
    height: 1vw;
    width: 1vw;
  }
}

@media only screen and (orientation: portrait) {
  .accordion__button:before {
    height: 1vh;
    width: 1vh;
  }
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
            animation: fadein 0.35s ease-in;
}

code {
    color: #00AEE9;
}

a {
  color: #00AEE9;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

