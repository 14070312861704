.App {
  text-align: center;
}

html {
  background-color: #ffffff;
}

.App-banner {
  margin-top: 7vh;
  margin-bottom: 7vh;
  justify-content: center;
  display: flex;
  flex-direction: row;
  max-height: 10vh;
  color: #1B295E;
}

.App-header {
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1B295E;
}

.App-logo {
  width: 10vh;
  height: auto;
  pointer-events: none;
}

.App-title {
  margin-left: 1.5vh;
  font-size: 3vh;
  font-weight: 1;
  color: #00AEE9;
}

.App-link {
  color: #61dafb;
}

.Commands {
  /* margin-top: 10vh; */
  margin-bottom: 6vh;
  display: flex;
  flex-direction: row;
}

.Toastify__toast-container {
  width: 400px;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

@media only screen and (orientation: landscape) {
  .App-footer {
    margin-top: 4vh;
    font-size: 1.2vh;
  }
}

@media only screen and (orientation: portrait) {
  .App-footer {
    margin-top: 4vw;
    font-size: 1.2vw;
  }
}
