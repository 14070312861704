.BackupButton {
  border: none;
  color: white;
  border-radius: 12px;
  opacity: 1;
  transition: 0.3s;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (orientation: landscape) {
  .BackupButton {
    width: 12vw;
    height: 8vw;
    font-size: 3vw;
  }
}

@media only screen and (orientation: portrait) {
  .BackupButton {
    width: 15vh;
    height: 10vh;
    font-size: 3vh;
  }
}

.BackupButton:disabled {opacity: 0.5}

.BackupButton:hover:enabled {opacity: 0.8}